<template>
	<div class="menu-panel" :class="{ active: show, light: theme == 'light' }">
		<div class="menu-panel-header">
			<i class="icon-plus" @click="$emit('close')"></i>
		</div>
		<div class="menu-panel-body">
			<a :href="legalLinks.privacy">{{ $t('common.menu.policy') }}</a>
			<a :href="legalLinks.tos">{{ $t('common.menu.terms') }}</a>
			<ZendeskLink event-name="FAQ - Click - Contact Us">{{ $t('common.menu.contact_us') }}</ZendeskLink>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ZendeskLink from '@/components/landing/header/ZendeskLink.vue'

export default {
	name: 'MobileMenu',
	components: { ZendeskLink },
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		theme: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapGetters({
			language: 'i18n/getLanguage',
			legalLinks: 'i18n/getLegalLinks',
		}),
	},
	watch: {
		show: {
			immediate: true,
			handler: function (value) {
				if (value) {
					document.body.classList.add('menu-open')
				} else {
					document.body.classList.remove('menu-open')
				}
			},
		},
	},
	methods: {
		goToLogin() {
			this.$analytic.logEvent('Click Login Link')
			this.$router.push({ name: 'account' })
		},
	},
}
</script>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
.menu-panel {
	position: fixed;
	top: 0;
	right: 0;
	width: 70%;
	height: 100%;
	background-color: #7d8bf7;
	z-index: 7;
	transform: translateX(100%);
	visibility: hidden;
	transition: transform 0.3s, visibility 0.3s;

	&.active {
		transform: translateX(0);
		visibility: visible;
	}

	&-header {
		padding: 1.2rem;

		i {
			color: #fff;
			font-size: 1.2rem;
			transform: rotate(45deg);
			display: inline-block;
			opacity: 0.8;
			padding: 0.3rem;
		}
	}

	&-body {
		text-align: center;

		a {
			color: #fff;
			display: block;
			padding: 0.7rem 0;
			margin: 0.3rem 0px;
			font-size: 1.1rem;
			font-weight: bold;

			&.active {
				// background-color: rgba(255,255,255,.1);//
				color: #ffe000;
			}
		}
	}

	&.light {
		background-color: #fff;
		font-family: var(--font);
	}

	&.light & {
		&-header {
			i {
				color: #000;
			}
		}

		&-body {
			a {
				color: #000;
			}
		}
	}
}

[data-theme='dark'] {
	.menu-panel {
		background-color: var(--background-color);
	}
}
</style>
