<template>
	<a :href="FEAT_LINK_SUPPORT" target="_blank" @click.prevent="handleLinkClick">
		<slot></slot>
	</a>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'ZendeskLink',
	props: {
		// eslint-disable-next-line vue/require-default-prop
		eventName: {
			type: String,
		},
	},
	computed: {
		...mapGetters({
			FEAT_LINK_SUPPORT: 'coreFeatures/FEAT_LINK_SUPPORT',
		}),
	},
	methods: {
		handleLinkClick() {
			this.$analytic.logEvent(this.eventName)
			window.open(this.FEAT_LINK_SUPPORT, '_blank')
		},
	},
}
</script>
